@font-face{
	font-family : "SugarBomb";
	src:  url("./font/SugarBomb.ttf");
}

body{
    border-width: 0;
    background-color: #111;
    font-family: SugarBomb !important;

    &::-webkit-scrollbar{
		width : 4px;
        height: 4px;
	}

	&::-webkit-scrollbar-track{
		background-color: #e4e4e4;
		border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #555;
	}
}


.main-background{
    position: relative;

    img{
        user-select: none;
        position: absolute;
    }
    a{
        position: absolute;
        &:hover{
            background-color: #ffffff20;
        }
    }
}
